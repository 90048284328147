@import '../../styles/settings/colors.module';
@import '../../styles/settings/spaces.module';
@import '../../styles/settings/gradients.module';
@import '../../styles/settings/fonts.module';
@import '../../styles/tools/mq';

.shell {
  height: 100%;

  &__wrapper {
    background-image: linear-gradient(to bottom right, $color-primary-600, $color-primary-800);
    display: flex;
    height: 100%;
  }

  &__company-logo {
    img {
      width: 80px;
    }
  }

  &__img {
    flex: 1;
    background-image: url(../Images/login-bg-customer.jpg);
    height: auto;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;

    &--operator {
      background-image: url(../Images/login-bg-operator.jpg);
    }

    &--customer {
      background-image: url(../Images/login-bg-customer.jpg);
    }

    &--provider {
      background-image: url(../Images/login-bg-provider.jpg);
    }

    &--briefing {
      background-image: url(../Images/login-bg-briefing.png);
      flex-direction: column;
      justify-content: flex-end;

      div:first-child {
        height: 20%;
        display: table;
      }

      div:nth-child(2) {
        background-image: url(../Images/login-bg-briefing-form.png);
        background-size: contain;
        background-position: bottom;
        background-repeat: no-repeat;
        height: 80%;
      }
    }

    @include mq($until: tablet) {
      display: none;
    }
  }

  &__content {
    padding: $space-3 $space-6;
    width: 530px;
    background-color: $color-white;
    height: 100%;
    overflow: auto;

    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .primary {
      color: $color-primary-600;
    }

    @include mq($until: tablet) {
      padding: $space-2 $space-3;
      flex: 1;
    }

    &--between {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .shell__body {
        margin-top: 0;
      }
    }

    header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      p {
        font-weight: 300;
        font-size: $font-size-sm;

        svg {
          margin-left: $space;
        }

        a {
          display: block;
          width: 100%;
          font-weight: 400;
          text-align: right;
        }
      }
    }

    h1 {
      font-size: 24px;
      margin-bottom: $space-2;
      font-weight: 400;
      white-space: pre-line;
      line-height: 30px;

      @include mq($until: tablet) {
        font-size: 24px;
      }
    }

    a + a {
      margin-left: $space-2;
      text-align: right;
    }
  }

  &__body {
    @include mq($until: tablet) {
      margin-top: $space * 8.5;
    }

    svg + h1 {
      margin-top: $space-4;
    }

    p {
      margin-bottom: $space-3;
      font-weight: 300;
    }

    button {
      margin-top: $space-2;
    }
  }

  &__body-actions {
    display: flex;
    justify-content: space-between;
    margin-top: $space-2;

    p {
      display: inline-block;
    }

    button + button {
      margin-left: $space-2;
    }
  }

  &__footer {
    margin-top: $space-2;
    display: flex;

    &--between {
      justify-content: space-between;
    }

    &--center {
      justify-content: center;
    }

    &--with-spaces {
      padding: 0 $space-5;

      @include mq($until: tablet) {
        padding: 0 $space-3;
      }
    }

    a,
    a:hover,
    a:focus,
    a:visited,
    a:link {
      color: $color-neutral-500;
    }

    svg {
      color: $color-neutral-700;
    }

    a {
      font-size: $font-size-sm;
    }

    p {
      font-weight: 300;
    }

    small {
      font-size: $font-size-xs;
    }
  }

  &__content-securities {
    display: flex;
    justify-content: space-between;
    flex: 1;

    u,
    p {
      font-weight: 600;
    }

    span {
      font-size: $font-size-xs;
      font-family: $font-family;
      text-align: left;
      width: 120px;
      line-height: 10px;
      color: $color-neutral-500;
    }
  }

  &__security-images {
    display: flex;
    justify-content: center;

    a + svg {
      margin-left: $space-2;
    }
  }
}
